.unit-feedbacks-v1 {
	.avg-generell {
		padding-bottom: 20px;

		.avg-count {
			font-weight: 700;
			font-size: 18px;
		}
	}

	.fb-kat {
		padding: 10px 0;
	}

	.feedback-bl {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;



		@media (max-width:450px) {
			flex-direction: column;
			gap: 15px;

		}

		.criteria {
			width: 49%;
			margin-bottom: 15px;

			@media (max-width:450px) {
				width: 100%;
				margin-bottom: 0;
			}
		}
	}

	.avg-symbol {
		display: inline-block;
		background-color: var(--bg-color-feedback);
		color: var(--font-color-feedback);
		padding: 3px;
		border-radius: var(--border-radius);
		font-weight: bold;
		font-size: 18px;
	}

	.avg-details {
		display: flex;
		flex-direction: row;
		justify-content: left;
		flex-wrap: wrap;
		margin-bottom: 20px;

		.criteria {
			text-align: center;
			padding-right: 2px;
			border-radius: var(--border-radius);
			width: 25%;
			padding-bottom: 2px;

			@media (max-width:1200px) {
				width: 49%;
			}

			@media (max-width: 560px) {
				width: 100%;
			}

			.fb-content {
				background-color: var(--color-grey-light);
				padding: 5px;
				padding-top: 10px;
				border-radius: var(--border-radius);
				min-height: 95px;

				@media (max-width: 767px) {
					min-height: 90px;
				}

				@media (max-width: 560px) {
					min-height: 10px;
				}

				.name {
					padding-top: 5px;
					font-size: var(--font-size-md);
				}

				.avg-symbol {
					font-size: 14px;
					min-width: 35px;
				}
			}
		}
	}

	.fb-entries {
		.guest-avg {
			width: 100%;
			display: flex;
			flex-direction: row;
			gap: 5px;
			align-items: center;

			.avg-symbol {
				font-size: var(--font-size-normal);
				margin-bottom: 16px;
				text-align: center;
				width: 90px;
			}

			.criteria {
				font-size: var(--font-size-md);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-bottom: 2px;

				.avg-symbol {
					margin-bottom: 0px;
					max-width: 60px;
					min-width: 60px;
				}

				.name {}
			}
		}

		.guest-info {
			margin-bottom: 10px;

			span {
				font-size: var(--font-size-sm);
				padding-right: 5px;

				.fa {
					padding-right: 5px;
				}
			}
		}

		.fb-entry {
			margin-bottom: 30px;
			padding-top: 30px;
			border-top: 1px solid var(--color-grey-dark);

			.comment {
				position: relative;

				.avg-symbol {
					font-size: var(--font-size-md);

					@media (min-width: 768px) {
						display: none;
					}
				}



				.guest-text {
					font-size: var(--font-size-md);
					background-color: var(--color-grey-light);
					padding: 8px;
					margin-bottom: 10px;
					border-radius: var(--border-radius);
				}

				.agency-comment {
					font-size: var(--font-size-md);
					padding: 8px;
				}
			}
		}
	}

	.button-line {
		width: 100%;
		margin-top: 30px;
		margin-bottom: 30px;
	}

}