/* barlow-semi-condensed-300 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-300.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-300.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-regular - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-600 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-600.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-600.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-700 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-700.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-700.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/playfair-display-v29-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/playfair-display-v29-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/Raleway-Bold.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Raleway-Bold.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/Raleway-SemiBold.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Raleway-SemiBold.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/Raleway-Regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Raleway-Regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}



/*@font-face {
	font-family: 'Font Awesome 6 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("https://r.v-office.com/webfonts/fontawesome-pro/fa-regular-400.eot");
	src: url("https://r.v-office.com/webfonts/fontawesome-pro/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-regular-400.woff2") format("woff2"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-regular-400.woff") format("woff"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-regular-400.ttf") format("truetype"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-regular-400.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 6 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("https://r.v-office.com/webfonts/fontawesome-pro/fa-brands-400.eot");
	src: url("https://r.v-office.com/webfonts/fontawesome-pro/fa-brands-400.eot?#iefix") format("embedded-opentype"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-brands-400.woff2") format("woff2"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-brands-400.woff") format("woff"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-brands-400.ttf") format("truetype"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-brands-400.svg#fontawesome") format("svg");
}*/