.unit-features-v1 {
	.feature-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: var(--border-radius);
		border: 1px solid var(--color-grey-dark);
		background-color: var(--color-grey-light);

		.fa-fireplace:before {
			@media (max-width: 992px) {
				height: 45px;
				width: 36px;
			}
		}

		.fa-fireplace:before {
			@media (max-width: 767px) {
				height: 22px;
				width: 22px;
				top: 4px;
				left: 0;
			}
		}

		.fa-washing-machine:before {
			height: 32px;
			width: 24px;
		}

		.fa-dog-leashed:before {
			@media (max-width: 767px) {
				height: 24px;
				width: 24px;
				left: 2px;
				top: -1px;
			}
		}

		.fa-washing-machine:before {
			@media (max-width: 767px) {
				height: 18px;
				width: 20px;
			}
		}

		.fa-paw {
			&.cross {
				&:after {
					width: 1.5px;
					height: 30px;
				}
			}
		}

		.fa-city:before {
			@media (max-width: 767px) {
				margin: 0;
				top: 4px;
				height: 26px;
				width: 20px;
				margin-top: -6px;
			}
		}

		.fa-stack {
			&.pets-icon {
				@media (max-width: 767px) {
					width: 24px !important;
					left: -5px;
					top: -4px;
				}
			}
		}


		@media(max-width:767px) {
			flex-wrap: wrap;
			padding: 15px;
		}

		@media(min-width:768px) {
			padding-top: 15px;
		}

		.ul-nopints {
			max-width: 100%;
			margin-bottom: 0;
			display: flex;
			flex-wrap: wrap;

			@media(max-width:767px) {
				max-width: 100%;
			}

			li {
				display: block;
				text-align: center;
				font-size: 14px;
				margin-bottom: 5px;

				@media(min-width:768px) {
					width: 33%;
					margin-bottom: 15px;
				}

				@media(min-width:992px) {
					width: 20%;
					margin-bottom: 15px;
				}


				@media(max-width:767px) {

					display: inline-block;
					margin-right: 5px;
					width: 45%;
					text-align: left;
				}

				@media(max-width:560px) {
					font-size: 14px;
				}


				&.full-width {
					@media(max-width:767px) {
						width: 100%;
					}
				}

				i {
					@media (min-width:768px) {
						font-size: 25px;
						width: 100%;
						height: 25px;
						text-align: center;
						margin-bottom: 6px;
					}

					&.text-green {
						color: var(--color-secondary);
					}

					@media(max-width:767px) {
						/*margin-right: 5px;*/
						width: 25px;
					}

					&.fa-city {
						/*height: 19px;*/
						margin: 0;

						/*@media (max-width: 1199px) {
							height: 30px;
						}*/

						@media (max-width: 767px) {
							height: 16px;
						}

						&:before {
							height: 41px;
							vertical-align: text-bottom;
							width: 30px;
							border: 0;
							margin-top: -7px;

							@media (max-width: 1199px) {
								height: 40px;
								width: 30px;
							}

							@media (max-width: 767px) {
								height: 27px;
							}
						}
					}
				}

				.fa-stack {
					@media (min-width:768px) {
						margin: 0 auto;
						display: block;
						margin-bottom: 3px;
					}

					@media(max-width:767px) {
						width: 16px;
					}
				}
			}
		}

		.rating {
			i {
				color: var(--color-secondary);
			}
		}
	}
}