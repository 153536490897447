.search-offer-info-v1 {

	padding: 10px;
	background-color: var(--color-white);
	margin-bottom: 1.5rem;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
	border: 1px solid var(--color-grey-light);
	border-radius: var(--border-radius);
	font-size: 20px;

	.offer-legende {
		font-weight: bold;
	}

	.offer-value {
		font-weight: normal;
	}

	.nowrap {
		white-space: nowrap;
	}


}